import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import PostPreview from "../components/PostPreview"

const IndexPage = ({ data }) => {
  return (
    <Layout primaryHeader={true}>
      <SEO title="Home" />
      {data.allMdx.nodes.map(node => (
        <PostPreview key={`/blog/${node.slug}`} node={node} />
      ))}
    </Layout>
  )
}

export const query = graphql`
  {
    allMdx(sort: { order: DESC, fields: [frontmatter___date] }, limit: 1000) {
      nodes {
        slug
        frontmatter {
          title
          date(formatString: "MMM D, YYYY")
          spoiler
        }
      }
    }
  }
`

export default IndexPage
